import React from "react";
import heroImg from "../../assets/img/MarekSitting.png";
import MarekBlackLogo from "../../assets/img/MarekBlackLogo.png";
import "../../styles/hero.css";

const Hero = () => {
  return (
    <section className="hero_section" id="home">
      <div className="container">
        <div className="hero__wrapper">
          {/* ========= hero img ============= */}
          <div className="hero__img">
            <div className="hero__img-wrapper">
              <div data-aos="fade-right" data-aos-duration="2000" className="box__img">
                <img src={heroImg} alt="" />
              </div>
            </div>
          </div>
          {/* ========== hero content ========== */}
          <div className="hero__content">
            <h2
              className="section__title"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              grow<br />with<br />me.
            </h2>
            <p data-aos="fade-up" data-aos-delay="100" data-aos-duration="1800">
              Marek Pukl<br />your personal<br />trainer
            </p>
          </div>
          <div
            className="gym__location"
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            <img src={MarekBlackLogo} alt="Logo" />
          </div>
          <div
            className="gym__registration"
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            <span>Book now</span>
          </div>
        </div>
      </div>
    </section >
  );
};

export default Hero;
