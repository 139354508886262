import React from "react";
import "../../styles/exercises.css";

const Exercises = () => {
  return (
    <section className="wrapper" id="schedule">
      <div className="container exercise__container">
        <div className="exercise__top">
          <h2 className="section__title">
            About <span className="highlights">me</span>
          </h2>
        </div>
      </div>
    </section>
  );
};

export default Exercises;
